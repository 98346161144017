import { WppButton, WppPill, WppTag, WppTypography } from '@platform-ui-kit/components-library-react'
import { format } from 'date-fns'
import { useMemo } from 'react'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { ActivityPostItemRequest } from 'api/canvas/fetchers/createActivity'
import { useCreateActivity } from 'api/canvas/mutation/useCreateActivity'
import { usePatchActivityApi } from 'api/canvas/mutation/usePatchActivityApi'
import { Avatar } from 'components/common/avatar/Avatar'
import { Flex } from 'components/common/flex/Flex'
import { FormDatepicker } from 'components/form/formDatepicker/FormDatepicker'
import { FormInput } from 'components/form/formInput/FormInput'
import { FormSelect } from 'components/form/formSelect/FormSelect'
import { FormTextareaInput } from 'components/form/formTextareaInput/FormTextareaInput'
import { MultipleSelectWithPill, TagProps } from 'components/form/multipleSelectWithPill/MultipleSelectWithPill'
import { SideModal } from 'components/surface/sideModal/SideModal'
import { CanvasItemType } from 'constants/analytics'
import { useForm } from 'hooks/form/useForm'
import useEventSource from 'hooks/useEventSource'
import { useProject } from 'hooks/useProject'
import { useToast } from 'hooks/useToast'
import useWorkflowEditAnalytics from 'hooks/useWorkflowEditAnalytics'
import styles from 'pages/project/components/canvas/components/editPhaseModal/EditPhaseModal.module.scss'
import { SelectOsGroupForm } from 'pages/project/components/canvas/components/selectOsGroup/SelectOsGroupForm'
import { SelectPerson } from 'pages/project/components/canvas/components/selectPerson/SelectPerson'
import { getDate, useGetBaseFormValues } from 'pages/project/components/canvas/components/selectPerson/utils'
import { START_POSITION } from 'pages/project/components/canvas/fluidCanvas/utils'
import { TaskStatusKeys } from 'pages/project/components/canvas/hooks/useUpdateItem'
import {
  activityValidationScheme,
  invalidateCanvas,
} from 'pages/project/components/canvas/linearCanvas/components/item/utils'
import { useExternalStatusList } from 'pages/project/hooks/useExternalStatusList'
import { createProjectModal } from 'pages/project/utils/createProjectModal'
import { Members } from 'types/members/members'
import { ProjectRole } from 'types/permissions/permissions'
import { ProcessType } from 'types/projects/projects'
import { TaskStatus } from 'types/projects/tasks'
import { ActivityItem } from 'types/projects/workflow'
import { COMMON_VALIDATION, fullName, makeStringShorter, projectDateFormat } from 'utils/common'
import { NiceModalWrappedProps } from 'utils/createNiceModal'

interface Props extends NiceModalWrappedProps {
  projectId: string
  phaseId?: string
  activity?: ActivityItem
  isDisabled?: boolean
  selectedCanvas?: ProcessType
  isWrikeConnected?: boolean
  isTemplate?: boolean
}

const initialValues = {
  name: '',
  description: '',
}

const AddEditActivityModal = ({
  projectId,
  isWrikeConnected,
  phaseId,
  isOpen,
  activity,
  onClose,
  onCloseComplete,
  isDisabled,
  selectedCanvas = ProcessType.LINEAR,
  isTemplate,
}: Props) => {
  const isNew = !activity
  const { useExternalStatuses, wrikeWorkflows, project } = useProject() ?? {}
  const { trackItemEditCancelled, trackItemAdd, trackItemEdit } = useWorkflowEditAnalytics()
  const eventSource = useEventSource()

  const initialStatus = useExternalStatuses
    ? isNew
      ? wrikeWorkflows?.customStatuses[0]?.wrikeId
      : activity?.task?.wrike?.externalStatusId!
    : activity?.task?.status

  const defaultValues = {
    ...useGetBaseFormValues(activity ?? initialValues, projectId, initialStatus),
    description: activity?.description || '',
    ...(isTemplate && { groupId: activity?.group?.id || null }),
    hideFromMembers: activity?.hiddenFromAll ? project?.members : activity?.hiddenFrom || [],
  }

  const form = useForm({ defaultValues, validationSchema: activityValidationScheme })

  const { t } = useTranslation()
  const { showToast } = useToast()
  const { mutateAsync: handleCreateActivity } = useCreateActivity()
  const { mutateAsync: handleUpdateActivity } = usePatchActivityApi()

  const {
    handleSubmit,
    formState: { isSubmitting },
    watch,
  } = form

  const { hideFromMembers } = watch()

  const onSubmit = handleSubmit(
    async ({ dueDate, name, assignUser: users, description: newDescription, status, groupId }) => {
      try {
        const assignUser = users[0]

        const dates = dueDate ? getDate(dueDate) : {}
        const isHiddenFromAll = hideFromMembers?.length === project?.members?.length

        const formattedDates = {
          startDate: dates.startDate ? format(new Date(dates.startDate), projectDateFormat) : null,
          endDate: dates.endDate ? format(new Date(dates.endDate), projectDateFormat) : null,
        }

        if (isNew) {
          let body: ActivityPostItemRequest = {
            ...formattedDates,
            name: name?.trim() || '',
            assignUser: assignUser?.email || null,
            description: newDescription?.trim() || null,
            phaseId,
            projectId,
            [useExternalStatuses ? TaskStatusKeys.TASK_EXTERNAL_STATUS_ID : TaskStatusKeys.TASK_STATUS]: status,
            ...(isTemplate && { groupId: groupId || null }),
            ...(!isHiddenFromAll &&
              hideFromMembers && {
                hideFromMembers: hideFromMembers.map(member => member.id) ?? [],
              }),
            hideFromAll: isHiddenFromAll,
          }

          if (selectedCanvas === ProcessType.FLUID) {
            body = {
              ...body,
              coordinateX: START_POSITION.x,
              coordinateY: START_POSITION.y,
            }
          }

          await handleCreateActivity(body)
          showToast({
            type: 'success',
            message: t('project.canvas.toast.add_an_activity', {
              query: makeStringShorter(name),
            }),
          })

          if (project) {
            trackItemAdd({
              type: CanvasItemType.ACTIVITY,
              numberOfApps: 1,
            })
          }
        } else {
          const body = {
            ...formattedDates,
            name: name?.trim() || '',
            assignUser: assignUser?.email || null,
            description: newDescription?.trim() || null,
            [useExternalStatuses ? TaskStatusKeys.TASK_EXTERNAL_STATUS_ID : TaskStatusKeys.TASK_STATUS]: status,
            ...(isTemplate && { groupId: groupId || null }),
            ...(!isHiddenFromAll &&
              hideFromMembers && {
                hideFromMembers: hideFromMembers.map(member => member.id) ?? [],
              }),
            hideFromAll: isHiddenFromAll,
          }

          await handleUpdateActivity({
            id: activity?.id!,
            ...body,
          })

          showToast({
            type: 'success',
            message: t('project.canvas.toast.update_activity', { query: makeStringShorter(name) }),
          })

          if (project) {
            trackItemEdit({
              type: CanvasItemType.ACTIVITY,
              assignUser,
              eventSource,
            })
          }
        }

        await invalidateCanvas(!assignUser?.isMember)
        onClose()
      } catch (e) {
        const toastMsg = isNew
          ? 'project.canvas.toast.failed_operation_create'
          : 'project.canvas.toast.failed_operation_edit'
        showToast({ type: 'error', message: t(toastMsg, { query: 'activity' }) })
        console.log(e)
      }
    },
  )

  const externalStatuses = useExternalStatusList()

  const statusOptions = useMemo(() => {
    return Object.entries(TaskStatus).map(([key, value]) => ({
      value: key,
      label: t(`project.tasks.status.${value}`),
    }))
  }, [t])

  const handleCancelEdit = () => {
    onClose()

    if (project) {
      trackItemEditCancelled(CanvasItemType.ACTIVITY)
    }
  }

  const isHiddenFromAssigneeOrOwner = hideFromMembers?.some(
    member => member.email === activity?.assignUser || member.role === ProjectRole.OWNER,
  )

  const getTagProps = (member: Members): TagProps | undefined => {
    if (member.role === ProjectRole.OWNER) {
      return { label: 'Owner', categoricalColorIndex: 1 } // Return the tag props for owners
    }
    if (member.email === activity?.assignUser) {
      return { label: 'Assignee', variant: 'neutral' } // Return the tag props for assignee
    }
    return undefined
  }

  return (
    <FormProvider {...form}>
      <SideModal
        open={isOpen}
        formConfig={{ onSubmit }}
        onWppSideModalClose={handleCancelEdit}
        onWppSideModalCloseComplete={onCloseComplete}
        size="m"
        data-testid="activity-modal"
      >
        <WppTypography slot="header" type="2xl-heading">
          {isNew ? t('product.activity.create_modal_title') : t('product.activity.edit_modal_title')}
        </WppTypography>
        <Flex slot="body" direction="column" gap={24}>
          <FormInput
            name="name"
            labelConfig={{ text: t('product.activity.field_name') }}
            placeholder={t('product.activity.field_name_placeholder')!}
            required
            disabled={isDisabled}
            data-testid="activity-modal-name"
          />

          {!isTemplate && (
            <>
              <SelectPerson disabled={isDisabled} isWrikeConnected={isWrikeConnected} projectId={projectId} />
              <Flex justify="between" gap={20} align="end">
                <FormDatepicker
                  className={styles.datePicker}
                  name="dueDate"
                  placeholder="Start date | End date"
                  labelConfig={{ text: t('product.field_due_date') }}
                  range
                  disabled={isDisabled}
                />
              </Flex>
              <FormSelect
                name="status"
                type="single"
                data-testid="status-select"
                options={useExternalStatuses ? externalStatuses! : statusOptions}
                labelConfig={{ text: t('product.status_label') }}
                required
                withSearch
              />
            </>
          )}

          <FormTextareaInput
            name="description"
            labelConfig={{ text: t('product.activity.description') }}
            placeholder="Describe activity"
            warningThreshold={COMMON_VALIDATION.description.warning}
            charactersLimit={COMMON_VALIDATION.description.max}
            disabled={isDisabled}
            data-testid="modal-activity-description"
          />

          {!isTemplate && (
            <MultipleSelectWithPill
              name="hideFromMembers"
              options={project.members!}
              type="multiple"
              labelConfig={{
                text: t('modals.app_details_modal.hide_from_members'),
                icon: 'wpp-icon-info',
                description: t('modals.app_details_modal.hide_from_members_tooltip', { query: 'activity' }),
              }}
              placeholder={t('modals.app_details_modal.hide_from_members_placeholder')}
              withSearch
              infoMessage={
                isHiddenFromAssigneeOrOwner ? t('modals.app_details_modal.hide_from_members_info') : undefined
              }
              dropdownConfig={{ placement: 'top' }}
              getKey={member => member.id}
              renderItem={({ option: member }) => (
                <>
                  <span slot="label">{fullName(member.firstname, member.lastname)}</span>
                  <span slot="caption">{member.email}</span>

                  <WppTag
                    slot="right"
                    label={getTagProps(member)?.label}
                    variant={getTagProps(member)?.variant}
                    categoricalColorIndex={getTagProps(member)?.categoricalColorIndex}
                  />
                </>
              )}
              renderPill={({ option: member, onDeselect }) => (
                <WppPill size="m" type="display" key={member.id} removable onWppClose={onDeselect}>
                  <Flex align="center">
                    <Avatar
                      size="xs"
                      name={fullName(member.firstname, member.lastname)}
                      src={member.avatarUrl || ''}
                      style={{ marginRight: '6px' }}
                    />
                    {fullName(member.firstname, member.lastname)}
                  </Flex>
                </WppPill>
              )}
            />
          )}

          {isTemplate && <SelectOsGroupForm data-testid="modal-activity-group" initialValue={activity?.group} />}
        </Flex>
        <Flex slot="actions" justify="end" gap={12}>
          <WppButton variant="secondary" size="m" onClick={handleCancelEdit}>
            {t('common.btn_cancel')}
          </WppButton>
          <WppButton variant="primary" size="m" type="submit" loading={isSubmitting} disabled={isDisabled}>
            {isNew ? t('common.btn_add') : t('common.btn_save')}
          </WppButton>
        </Flex>
      </SideModal>
    </FormProvider>
  )
}

export const { showModal: showAddEditActivityModal } = createProjectModal<Props>(
  AddEditActivityModal,
  'add-edit-activity-modal',
)
