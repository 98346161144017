import { FramedAppMethods, FramedAppParentMethods } from '@wpp-open/core'
import { connectToChild, Methods } from 'penpal'
import { ComponentPropsWithoutRef, useEffect, useRef } from 'react'

import { useProjectChildContext } from 'pages/project/components/penpalParentFrame/useProjectChildContext'

type Props = ComponentPropsWithoutRef<'iframe'> & {
  parentUrl?: string
}
export const PenpalParentFrame = ({ parentUrl, ...iframeProps }: Props) => {
  const ref = useRef<HTMLIFrameElement>(null)
  const { osApi, osContext } = useProjectChildContext({ parentUrl })

  useEffect(() => {
    const parentMethods: Methods = {
      osApi: { ...osApi },
    } satisfies FramedAppParentMethods

    const connection = connectToChild<FramedAppMethods>({
      iframe: ref.current!,
      methods: parentMethods,
      debug: false,
    })

    connection.promise
      .then(child => {
        return child.receiveOsContext(osContext)
      })
      .catch(error => {
        console.error("Penpal parent context error':", error)
      })

    return () => {
      connection.destroy()
    }
  }, [osApi, osContext])

  // eslint-disable-next-line jsx-a11y/iframe-has-title
  return <iframe ref={ref} {...iframeProps} />
}
