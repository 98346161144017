import { OsContext, SelectedHierarchy } from '@wpp-open/core'
import { useOs } from '@wpp-open/react'
import { useMemo } from 'react'

import { useProject } from 'hooks/useProject'
import { resolveHierarchyPath, resolveSelectedHierarchy } from 'utils/coreContextValues'

// osContext does not contain all correct data for child apps
export const useProjectChildContext = ({ parentUrl }: { parentUrl?: string }) => {
  const { osApi, osContext: orchestrationOsContext } = useOs()
  const { project } = useProject()

  const osContext = useMemo(() => {
    const projectHierarchyIds = resolveHierarchyPath({
      validHierarchyId: project.contextWorkspace ?? null,
      navigationTree: orchestrationOsContext.navigationTree,
    })

    const projectHierarchy: SelectedHierarchy = resolveSelectedHierarchy({
      activeWorkspaceId: project.contextWorkspace ?? null,
      activeHierarchyWithHiddenLevel: projectHierarchyIds,
      navigationTreeWithHiddenLevel: orchestrationOsContext.navigationTree,
    })

    return structuredClone({
      ...orchestrationOsContext,

      // updated context
      //by  url
      baseUrl: parentUrl,
      // by project fields values
      hierarchy: projectHierarchy,
      project: {
        ...orchestrationOsContext.project,
        processType: project.processType,
        contextHierarchy: project.contextHierarchy,
      },
      additional: {
        ...orchestrationOsContext.additional,
        projectAzId: project.azId,
      },
    }) as OsContext
  }, [
    orchestrationOsContext,
    parentUrl,
    project.azId,
    project.contextHierarchy,
    project.contextWorkspace,
    project.processType,
  ])

  return {
    osApi,
    osContext,
  }
}
